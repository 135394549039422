import ApiService from "@/common/api.service";

export default {
  getFullTests(data) {
    return ApiService.query("/exams", data);
  },
  postAIExplanation(questionID, data) {
    return ApiService.post(`/ai/questions/${questionID}/ask`, data);
  }
};
